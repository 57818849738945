import React from "react"
import Layout from "../components/layout"
import wfhimage from "../images/workFromHome.jpg"

export default function Workingeffectively() {
  const   location = {
    href:"https://covidtracker.wjatt.com/workingeffectively.js"
}
  return (
    <Layout location={location}>
      <h2> Working Remotely Effectively </h2>
      <div>
        Author:
        <a
          href="https://tt.linkedin.com/in/veronica-debbie-joseph-caddle-89b9678b"
          target="_blank"
          rel="noopener noreferrer" 
        >
          {" "}
          V. Debbie Joseph-Caddle
        </a>{" "}
      </div>
      <div> Published on: 2nd April 2020</div>
      <br />
      <div style={{ marginBottom: "1rem" }}>
        <img src={wfhimage} alt="" style={{ marginBottom: "0px" }} />
        Photo by :
        <a
          href="https://unsplash.com/@annaauza?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span style={{ display: "inline-block", padding: "2px 3px" }}>
            Anna Auza
          </span>
        </a>
      </div>
      <article style={{fontSize:"1.2rem"}}>
        <p className="summary">
          This article is intended to provide some tips based on my personal
          experience that will capitalize on the benefits of working from home
          and at the same time minimize the downsides of it so that you can
          maintain effectiveness of your role and function, be productive by
          working smarter not harder. The key for me is a structured daily
          schedule, that is neither rigid nor too flexible, as it enables me to
          achieve the work-life balance that I want and provides space to
          respond to unscheduled situations.
        </p>
        Here are the key things to consider in preparing your structured
        schedule.
        <ol>
          <li>
            Create a space from which you can work comfortably, this will get
            your mind ready to work and help you to stay focused.
          </li>

          <li>
            Determine your peak productive hours based on lifestyle, personality
            type and responsibilities at work, at home and in community.
          </li>

          <li>
            Calculate your required or desired work hours per day or week based
            on contractual obligations and intention.
          </li>

          <li>
            Develop the schedule that will become your daily or weekly routine.
            You can use an excel sheet, online calendar or an App.
            <div>The key things to insert are:</div>
            <ol type="a">
              <li>
                Peak productive time during which you will do all focus work.{" "}
              </li>
              <li>Non-variable work commitments </li>
              <li>Non variable personal commitments </li>
              <li>
                Time for online meetings, communication with clients, service
                providers and other team members.{" "}
              </li>
              <li>
                Time for homecare, self-care and other non-work-related
                responsibilities.{" "}
              </li>
              <li>Rest, relaxation and Sleep </li>
            </ol>
          </li>

          <li>
            Prepare a workplan so that you can effectively achieve the expected
            outputs and meet the deadlines set. This planning takes some time
            and thought, but it allows you to assess in advance what is
            achievable and when it is achievable, based on the time required in
            hours and timing if it involves others There are many tools
            available to do this, I use a variation of the Eisenhower matrix
            which you can check out at <a href="https://www.eisenhower.me/eisenhower-matrix/">https://www.eisenhower.me/eisenhower-matrix/</a>
            
          </li>
          <li>
            Maximise the use of technology available to you by your company and
            online. Even if your company does not have special technology to
            facilitate remote working you can effectively use Emails, WhatsApp
            and telephone together with the range of free software available,
            like TeamViewer to share your screen with other team members and
            Zoom and Skype for online meetings. Use the one that is most
            appropriate for the task and speed of response required.
          </li>
          <li>
            Consider the realistic issues
            <ol type="a">
              <li>
                Others at home that may not understand the level of
                concentration and dedication you need to get your work done,
                would require a conversation with them so that they can
                understand that although you're working from home it doesn't
                mean you are available to them 24/7. Set realistic boundaries on
                times that they don’t disturb and on cues that will signal that
                you are in work mode.
              </li>
              <li>
                Getting into work mode - Do whatever gives your brain the mental
                cue to go into productive work mode, even if it means getting
                dressed as if you are going to an office.
              </li>
              <li>
                Human connection - To not feel disconnected from the rest of
                your team and isolated from family and the world it is important
                to schedule time to connect with others even if it is via
                technology.
              </li>
              <li>
                Self-Care - When the line between “work” and “home” starts to
                blur, you might find yourself stuck to your computer screen for
                a longer period. While that can sometimes be necessary to meet
                deadlines give yourself time for you. Commit to your fitness
                routine and make sure that you’re creating blocks in your
                schedule to eat healthy nutritious meals so that you can be
                focused and productive when you need to be.
              </li>
              <li>
                Social media is designed to make it easy for you to open and
                quickly browse for information and connection, which makes it a
                good tool and resource, but on the other hand, it can suck you
                in with all its “drama” and can become a hindrance to your
                productivity. If you have a compulsive personality, then do
                whatever is necessary to curb the impulse to log into them
                during your scheduled work hours and set a limited portion of
                your recreational time to do so.
              </li>
              <li>
                Time to “log on and off” is important from two perspectives
                <ol type="i">
                  <li>
                    Because you don’t have the visual cue of people leaving the
                    building to let you know it is the end of the workday, you
                    may hunker down for long hours and push till you are
                    overtired and the law of diminishing returns sets in.{" "}
                  </li>
                  <li>
                    As we become increasingly more connected via technology and
                    more people are working flexible hours, you may receive
                    emails and chat notifications at any hour of the day or
                    night. Set an appropriate time when you will go online to
                    read and respond and when you will log off from reading;
                    because depending on the content of the communication it may
                    affect you negatively even before you get into the day and
                    if it’s in the middle of the night it could affect your
                    sleep.{" "}
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        The above is not exhaustive, add whatever you think, and feel is
        relevant and experiment to develop a schedule that you’ll be able to
        sustain over time.
      </article>
    </Layout>
  )
}

